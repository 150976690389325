<template>
  <div class="position-relative password-widget">
    <b-form-input
      :size="size"
      :class="`${classWidget}`"
      :id="id"
      v-model.trim="model"
      :type="fieldType"
      pattern="(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Z]).*$"
      :placeholder="placeholder"
      title=""
      autocomplete="new-password"
      minlength="8"
      required
      @input="validatePassword"
      @focus="showPasswordWidget = true"
      @blur="showPasswordWidget = false"
    ></b-form-input>
    <div class="password-widget__content" v-if="showPasswordWidget">
      <h1>{{translations[2524]}}</h1>
      <ul class="m-0 p-0">
        <li class="d-flex align-items-start mb-2" :class="{'text-success': validation.capital, 'text-danger': !validation.capital}">
          <b-icon icon="check-lg" v-if="validation.capital" class="mr-2" scale="1.2" />
          <b-icon icon="x-lg" v-else class="mr-2" scale="1.1" />
          <span class="password-list-spacer" v-html="translations[2527]"></span>
        </li>
        <li  class="d-flex align-items-start mb-2" :class="{'text-success': validation.number, 'text-danger': !validation.number}">
          <b-icon icon="check-lg" v-if="validation.number" class="mr-2" scale="1.2" />
          <b-icon icon="x-lg" v-else class="mr-2" scale="1.1" />
          <span class="password-list-spacer" v-html="translations[2528]"></span>
        </li>
        <li  class="d-flex align-items-start mb-2" :class="{'text-success': validation.length, 'text-danger': !validation.length}">
          <b-icon icon="check-lg" v-if="validation.length" class="mr-2" scale="1.2" />
          <b-icon icon="x-lg" v-else class="mr-2" scale="1.1" />
          <span class="password-list-spacer" v-html="translations[2529]"></span>
        </li>
      </ul>
    </div>
  </div>

</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';

@Component
export default class Captcha extends Vue {
  @Prop({ default: '' }) value!: string;

  @Prop({ default: 'password' }) fieldType!: string;

  @Prop({ default: '' }) id!: string;

  @Prop({ default: '' }) titleWidget!: string;

  @Prop({ default: '' }) placeholder!: string;

  @Prop({ default: '' }) size!: string;

  @Prop({ default: '' }) classWidget!: string;

  showPasswordWidget = false;

  validation = {
    capital: false,
    number: false,
    length: false,
  }

  get translations() {
    return AppModule.translation;
  }

  get model() {
    return this.value;
  }

  set model(val: string) {
    this.$emit('input', val);
  }

  validatePassword(val: any) {
    const password = val;
    this.validation.capital = /[A-Z]/.test(password); // Проверка на заглавную букву
    this.validation.number = /\d/.test(password); // Проверка на число
    this.validation.length = password.length >= 8; // Проверка на длину
  }

  mounted() {
    this.validatePassword(this.model);
  }
}
</script>
<style scoped lang="scss">

.password-widget {
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  &__content {
    padding: 15px;
    background: #fefefe;
    font-size: .875em;
    border-radius: 5px;
    box-shadow: 0 1px 3px #ccc;
    border: 1px solid #ddd;
    //display: none;
    width: 350px;
    z-index: 5;
    position: absolute;
    left: 0;
    top: 100%;
  }
  h1 {
    margin: 0 0 10px 0;
    padding: 0;
    font-weight: normal;
    font-size: 13px;
  }
  ul {
    li {
      line-height: 16px;
    }
  }
}
</style>
